import React, {useState} from "react";
import { styled } from "@material-ui/styles";
import { Dialog, IconButton } from "@material-ui/core";
import { ITaskItem } from "../RoutineController";
import { MorePopUp } from "../../../../components/src";
interface Props {
    open: boolean;
    onClose?: () => void;
    routine: ITaskItem;
    editOptions?: {
        label: string, 
        icon: any, 
        onClick: () => void,
        hide?: boolean,
        stayOpenAfterItemClicked?: boolean
    }[];
    deleteOptions?: {
        label: string, 
        icon: any, 
        onClick: () => void,
        hide?: boolean,
        stayOpenAfterItemClicked?: boolean
    }[];
}


const RoutineDetailPopup: React.FC<Props> = (props: Props) => {
    const { 
        open, 
        onClose, 
        routine,
        editOptions,
        deleteOptions
    } = props;

    const [deleteAnchorEle, setDeleteAnchorEle] = useState<HTMLDivElement | null>(null)
    const [editAnchorEle, setEditAnchorEle] = useState<HTMLDivElement | null>(null)

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                style: {
                    background: "transparent",
                    borderRadius: "1rem"
                }
            }}
        >
            <StyledRoutineDetail className="RoutineDetailPopup_StyledRoutineDetail">
                <div className="header">
                    <IconButton
                        onClick={onClose}
                    >
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.22688 9.83555L0.163452 8.77213L3.93655 4.99902L0.163452 1.25092L1.22688 0.1875L4.99998 3.9606L8.74808 0.1875L9.8115 1.25092L6.0384 4.99902L9.8115 8.77213L8.74808 9.83555L4.99998 6.06245L1.22688 9.83555Z" fill="white" />
                        </svg>
                    </IconButton>
                </div>
                <div className="body">
                    <header>{routine.title}</header>
                    <p className="description">{routine.description}</p>
                    <p className="time">
                        <span>{routine.start_time}</span>
                        {
                            routine.end_time && (
                                <span>{" "}to {routine.end_time}</span>
                            )
                        }
                    </p>
                    <div className="recurrence">
                        {routine.recurrence}
                    </div>
                    <div className="actions">
                        <div className="item" onClick={(event)=> setEditAnchorEle(event.currentTarget)}>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.00001 16H3.26153L13.4981 5.7634L12.2366 4.50188L2.00001 14.7385V16ZM0.500031 17.5V14.1154L13.6904 0.930775C13.8416 0.793426 14.0086 0.687292 14.1913 0.612375C14.374 0.537458 14.5657 0.5 14.7662 0.5C14.9666 0.5 15.1609 0.535584 15.3488 0.60675C15.5368 0.6779 15.7032 0.791034 15.8481 0.94615L17.0692 2.18268C17.2243 2.32754 17.3349 2.49424 17.4009 2.68278C17.467 2.87129 17.5 3.05981 17.5 3.24833C17.5 3.44941 17.4656 3.64131 17.397 3.82403C17.3283 4.00676 17.219 4.17373 17.0692 4.32495L3.88461 17.5H0.500031ZM12.8563 5.1437L12.2366 4.50188L13.4981 5.7634L12.8563 5.1437Z" fill="white" />
                            </svg>
                        </div>
                        <div className="item" onClick={(event)=> setDeleteAnchorEle(event.currentTarget)}>
                            <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.30773 17.5017C2.80901 17.5017 2.38306 17.3251 2.02986 16.9719C1.67664 16.6187 1.50003 16.1928 1.50003 15.694V3.00176H0.500031V1.50179H5.00001V0.617188H11V1.50179H15.5V3.00176H14.5V15.694C14.5 16.1992 14.325 16.6267 13.975 16.9767C13.625 17.3267 13.1974 17.5017 12.6923 17.5017H3.30773ZM13 3.00176H3.00001V15.694C3.00001 15.7838 3.02886 15.8575 3.08656 15.9152C3.14426 15.9729 3.21798 16.0018 3.30773 16.0018H12.6923C12.7692 16.0018 12.8397 15.9697 12.9039 15.9056C12.968 15.8415 13 15.771 13 15.694V3.00176ZM5.40388 14.0018H6.90386V5.00176H5.40388V14.0018ZM9.09616 14.0018H10.5961V5.00176H9.09616V14.0018Z" fill="white" />
                            </svg>
                        </div>
                        <div className="item">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM8 12.17L13.88 6.29C14.27 5.9 14.91 5.9 15.3 6.29C15.69 6.68 15.69 7.31 15.3 7.7L8.71 14.29C8.32 14.68 7.69 14.68 7.3 14.29L4.71 11.7C4.32 11.31 4.32 10.68 4.71 10.29C4.89683 10.1027 5.15048 9.99751 5.415 9.99751C5.67952 9.99751 5.93317 10.1027 6.12 10.29L8 12.17Z" fill="white" />
                            </svg>
                        </div>
                        <div className="item">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM9.72 5H9.78C10.18 5 10.5 5.32 10.5 5.72V10.26L14.37 12.56C14.72 12.76 14.83 13.21 14.62 13.55C14.42 13.89 13.98 13.99 13.64 13.79L9.49 11.3C9.18 11.12 9 10.79 9 10.44V5.72C9 5.32 9.32 5 9.72 5Z" fill="white" />
                            </svg>
                        </div>
                    </div>
                </div>
                {
                    editAnchorEle && editOptions && (
                        <MorePopUp 
                            menuItems={editOptions}
                            anchorElement={editAnchorEle}
                            hideIcon={true}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center"
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "center"
                            }}
                            onClose={()=> setEditAnchorEle(null)}
                        />
                    )
                }
                {
                    deleteAnchorEle && deleteOptions && (
                        <MorePopUp 
                            menuItems={deleteOptions}
                            anchorElement={deleteAnchorEle}
                            hideIcon={true}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center"
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "center"
                            }}
                            onClose={()=> setDeleteAnchorEle(null)}
                        />
                    )
                }
            </StyledRoutineDetail>
        </Dialog>
    )
}

const StyledRoutineDetail = styled("div")({
    "&.RoutineDetailPopup_StyledRoutineDetail": {
        width: "36.5625rem",
        height: "31.6875rem",
        background: "rgba(35, 64, 75, 1)",
        display: "flex",
        flexDirection: "column",
        "& > .header": {
            width: "100%",
            height: "3.6875rem",
            boxSizing: "border-box",
            padding: "1rem",
            borderBottom: "1px solid #2d4953",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center"
        },
        "& > .body": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            flexDirection: "column",
            textAlign: "center",
            gap: "1.5rem",
            padding: "1rem",
            boxSizing: "border-box",
            "& > header": {
                fontSize: "1.25rem",
                fontWeight: 500,
                color: "rgba(255, 255, 255, 1)"
            },
            "& > .description": {
                fontSize: "0.875rem",
                fontWeight: 400,
                color: "rgba(255, 255, 255, 0.8)",
                textOverflow: "ellipsis",
                overflow: "hidden",
                display: "-webkit-box",
                "-webkit-box-orient": "vertical",
                "-webkit-line-clamp": 5
            },
            "& > .time": {
                fontSize: "0.875rem",
                fontWeight: 700,
                color: "rgba(255, 255, 255, 1)"
            },
            "& > .recurrence": {
                fontSize: "1rem",
                fontWeight: 400,
                color: "rgba(255, 255, 255, 1)",
                border: "1px solid rgba(255, 255, 255, 1)",
                borderRadius: "1.4375rem",
                width: "6.25rem",
                height: "2rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            "& > .actions": {
                display: "flex",
                alignItems: "center",
                gap: "2rem",
                flexWrap: "wrap",
                "& > .item": {
                    width: "2.625rem",
                    height: "2.625rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "rgba(48, 55, 70, 1)",
                    cursor: "pointer",
                    borderRadius: "100%"
                }
            }
        },
        [`@media screen and (max-width: 650px)`]: {
            width: "100%"
        },
    }
})

export default RoutineDetailPopup;