import React from "react";

// Customizable Area Start
import {
  Menu,
  Avatar
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import {
  logo,
  homeIcon,
  exploreIcon,
  bucketListIcon,
  eventsIcon,
  reminderIcon,
  appointmentsIcon,
  group3Icon,
  chatIcon,
  contactUsIcon,
  searchIcon,
  expandMoreIcon,
  personIcon,
  logoutIcon,
  Wave
} from "./assets";
import { DropDownIcon } from "../../user-profile-basic/src/assets";
import { checkedBoxIcon, emptyCheckBoxIcon } from "../../appointmentmanagement/src/assets";
import GlobalSearch from "../../advancedsearch/src/GlobalSearch.web";
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  SideNavbar = () => {
    return (
      <>
        <div className="logo-container">
          <div onClick={()=> this.redirectTo("NavigationMenu")}>
            <img src={logo} />
            <p>T.I.E</p>
          </div>
        </div>
        <div className="nav-menu-items">
          <div className={`nav-menu-item ${this.state.selectedNavItem === 1 ? 'active' : ''}`} onClick={() => this.setActiveNavMenuItem(1)}>
            <div className="home-icon"></div>
            <p>Home</p>
          </div>
          <div className={`nav-menu-item ${this.state.selectedNavItem === 2 ? 'active' : ''}`} onClick={() => this.setActiveNavMenuItem(2)}>
            <div className="explore-icon"></div>
            <p>Explore</p>
          </div>
          <div className={`nav-menu-item ${this.state.selectedNavItem === 3 ? 'active' : ''}`} onClick={() => this.setActiveNavMenuItem(3)}>
            <div className="bucket-list-icon"></div>
            <p>Bucket List</p>
          </div>
          <div className={`nav-menu-item ${this.state.selectedNavItem === 4 ? 'active' : ''}`} onClick={() => this.setActiveNavMenuItem(4)}>
            <div className="events-icon"></div>
            <p>Events</p>
          </div>
          <div className={`nav-menu-item ${this.state.selectedNavItem === 5 ? 'active' : ''}`} onClick={() => this.setActiveNavMenuItem(5)}>
            <div className="reminder-icon"></div>
            <p>Reminders</p>
          </div>
          <div className={`nav-menu-item ${this.state.selectedNavItem === 6 ? 'active' : ''}`} onClick={() => this.setActiveNavMenuItem(6)}>
            <div className="appointments-icon"></div>
            <p>Appointments</p>
          </div>
          <div className={`nav-menu-item ${this.state.selectedNavItem === 7 ? 'active' : ''}`} onClick={() => this.setActiveNavMenuItem(7)}>
            <div className="group-icon"></div>
            <p>Groups</p>
          </div>
          <div className={`nav-menu-item ${this.state.selectedNavItem === 8 ? 'active' : ''}`} onClick={() => this.setActiveNavMenuItem(8)}>
            <div className="chat-icon"></div>
            <p>Chats</p>
          </div>
          <div className={`nav-menu-item ${this.state.selectedNavItem === 9 ? 'active' : ''}`} onClick={() => this.setActiveNavMenuItem(9)}>
            <div className="contact-us-icon"></div>
            <p>Contact Us</p>
          </div>
        </div>
      </>
    )
  }

  ProfileMenu = () => {
    return (
      <CustomMenu
        id="profile-menu"
        anchorEl={this.state.profileMenuRef}
        open={Boolean(this.state.profileMenuRef)}
        onClose={this.closeProfileMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
      >
        <ProfileMenuContainer className="nav-profile-menu">
          <div className="profile-menu-item">
            <div className="image-container">
              <img src={personIcon} alt="personIcon.svg" />
            </div>
            <p onClick={()=> this.redirectTo("UserProfile")}>Profile</p>
          </div>
          <div data-test-id="logout-btn" className="profile-menu-item" onClick={this.logout}>
            <div className="image-container">
              <img src={logoutIcon} alt="logoutIcon.svg" />
            </div>
            <p>Logout</p>
          </div>
        </ProfileMenuContainer>
      </CustomMenu>
    )
  }

  SearchOptionMenu = ()=>{
    const {
      searchOptionsMenuAnchorEle,
      searchOptions
    } = this.state;
    return (
      <Menu
        open={Boolean(searchOptionsMenuAnchorEle)}
        anchorEl={searchOptionsMenuAnchorEle}
        PaperProps={{
          style:{
            background: "transparent"
          }
        }}
        MenuListProps={{
          style:{
            padding: 0
          }
        }}
        onClose={this.closeSearchOptionsMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
      >
        <StyledSearchOptionsMenu className="NavigationMenu_StyledSearchOptionsMenu">
           <ul>
            {
              Object.keys(searchOptions).map(key=>{
                 return (
                  <li key={key} onClick={()=> this.onSelectSearchOption(key)}>
                    <img src={ searchOptions[key].selected ? checkedBoxIcon : emptyCheckBoxIcon } />
                    {searchOptions[key].label}
                  </li>
                 )
              })
            }
           </ul>
        </StyledSearchOptionsMenu>
      </Menu>
    )
  }

  SearchOptions = () => {
    const {
      searchOptions
    } = this.state;
    return (
      <>
        <StyledSearchOptions className="NavigationMenu_StyledSearchOptions" onClick={this.openSearchOptionsMenu}>
          <p>
            {
              searchOptions["all"].selected ? searchOptions["all"].label : (
                Object.keys(searchOptions).map(key => {
                  if (searchOptions[key].selected) {
                    return searchOptions[key].label
                  }
                }).filter(value=> !!value).join(", ")
              )
            }
          </p>
          <img src={DropDownIcon} />
        </StyledSearchOptions>
        {this.SearchOptionMenu()}
      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { user, searchQuery, searchOptions } = this.state;
    return (
      <NavigationMenuContainer className="navigation-container">
        <div className="radial-grad top"></div>
        <div className="radial-grad bottom"></div>
        <img src={Wave} />
        <SideNavigationMenuContainer className={this.state.webDrawer ? "collapse side-navigation-menu-container" : 'side-navigation-menu-container'}>
          {this.SideNavbar()}
        </SideNavigationMenuContainer>
        <div style={{padding: "1rem 1rem 1rem 0", flex:1}} className={this.state.webDrawer ? "right-expand" : 'right-shrink'}>
          <RightSideNavigationMenuContainer className="right-side-navigation-menu">
            <div className={`toggle-nav-menu-btn ${this.state.webDrawer ? 'collapse' : ''}`} onClick={this.toggleDrawer}>
              <img src={expandMoreIcon} />
            </div>
            <div className="nav-container">
              <div className="search-bar-container">
                <div className="search-bar">
                  <img src={searchIcon} alt="search-icon.svg" />
                  <input onChange={(event)=>{
                    const sanitizedValue = event.target.value.replace(/^\s+/, '');
                    this.onSearch(sanitizedValue)
                  }} />
                  <div className="search-options">
                      {this.SearchOptions()}
                  </div>
                </div>
              </div>
              <div className="profile-container" onClick={this.openProfileMenu}>
                <Avatar src={user?.image_url} alt={user?.full_name} />
                <p>{user?.full_name}</p>
                <img className={`${this.state.profileMenuRef ? 'open' : ''}`} src={expandMoreIcon} />
              </div>
            </div>
            {this.ProfileMenu()}
            <div className="drawer-content" style={{ overflowY: 'auto', marginBottom: '1.5rem', height: "100%" }}>
              {
                searchQuery && (
                  <GlobalSearch {...this.props} searchOptions={searchOptions} searchQuery={searchQuery} />
                )
              }
              {
                !searchQuery && (
                 <>
                   {this.props.children}
                 </>
                )
              }
              
            </div>
          </RightSideNavigationMenuContainer>
        </div>
      </NavigationMenuContainer>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyle = {
  userProfileWrapper: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 30,
  },
  userProfile: {
    width: 100,
  },
  userDesignation: {
    fontSize: 14,
    color: "#ccc",
  },
  logout: {
    color: "#2196F3",
    marginTop: 15,
    marginBottom: 5,
  },
  drawerItemIcon: {
    width: 20,
    selfAlign: "center",
    marginLeft: 7,
  },
};

const NavigationMenuContainer = styled('div')({
  "&&.navigation-container": {
    height: '100%',
    backgroundColor: '#0F2631',
    display: 'flex',
    position: "relative",
    overflow: "hidden",
    "& .right-expand": {
      width: 'calc(100% - 5rem)',
      boxSizing: "border-box"
    },
    "& .right-shrink": {
      width: 'calc(100% - 14.5rem)',
      boxSizing: "border-box"
    },
    "& > .radial-grad":{
        width: "50rem",
        height: "50rem",
        position: "absolute",
        background: "radial-gradient(rgba(22, 155, 139, 1), transparent, transparent)",
        borderRadius: "100%",
        opacity: "0.5",
        zIndex: 1,
        "&.top":{
          transform: "translate(-27rem, -25rem)",
        },
        "&.bottom":{
          bottom: "-37rem"
        }
    },
    "& > img":{
      position: "absolute",
      width: "100vw",
      height: "100vh",
      zIndex: 1
    },
    [`@media screen and (max-width: 960px)`]: {
      "& .right-expand": {
        width: 'calc(100% - 5rem)',
        boxSizing: "border-box"
      },
    },
  }
})

const SideNavigationMenuContainer = styled('div')({
  "&&.side-navigation-menu-container": {
    width: '14.5rem',
    // height:'100%',
    boxSizing: "border-box",
    display: 'flex',
    flexShrink: 0,
    flexDirection: 'column',
    padding: "1rem 1rem 1rem 1rem",
    transition: 'all 0.3s ease-in-out',
    overflowY: 'auto',
    'scrollbar-width': 'none',
    '-ms-overflow-style': 'none',
    position: "relative",
    zIndex: 2,
    "&::-webkit-scrollbar": {
      width: 0
    },
    "&.collapse": {
      width: '5rem',
      padding: "1rem 0rem 1rem 0rem",
      "& .nav-menu-items": {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        "& .nav-menu-item": {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '50%',
          padding: '0',
          width: '3rem',
          height: '3rem',
          "& p": {
            display: 'none'
          }
        }
      }
    },
    "& .logo-container": {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: "center",
      alignItems: "center",
      "& > div": {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "center",
        alignItems: "center",
        fontSize: '1rem',
        color: "#f6f6f6",
        fontWeight: 700,
        letterSpacing: '2px',
        cursor: "pointer",
        "& img": {
          width: '3rem',
        }
      }
    },
    "& .nav-menu-items": {
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem",
      marginTop: '1.25rem',
      // transition:'all 1s ease-in-out',
      "& .nav-menu-item": {
        display: 'flex',
        alignItems: "center",
        gap: "0.625rem",
        padding: "0 0.75rem",
        height: '3rem',
        borderRadius: "62.5rem",
        color: "#fff",
        cursor: "pointer",
        opacity: 0.5,
        fontSize: "1rem",
        transition: 'all 0.2s ease-in-out',
        overflow: "hidden",
        textWrap: "nowrap",
        "&.active": {
          background: 'linear-gradient(90deg, #FF7F77 0%, #FF6376 100%)',
          opacity: 1
        },
        "& p": {
          margin: 0,
          textWrap: "nowrap"
        },
        "& .home-icon": {
          backgroundColor: "#fff",
          '-webkit-mask': `url(${homeIcon}) no-repeat center`,
          'mask': `url(${homeIcon}) no-repeat center`,
          width: '1.5rem',
          height: '1.5rem'
        },
        "& .explore-icon": {
          backgroundColor: "#fff",
          '-webkit-mask': `url(${exploreIcon}) no-repeat center`,
          'mask': `url(${exploreIcon}) no-repeat center`,
          width: '1.5rem',
          height: '1.5rem'
        },
        "& .bucket-list-icon": {
          backgroundColor: "#fff",
          '-webkit-mask': `url(${bucketListIcon}) no-repeat center`,
          'mask': `url(${bucketListIcon}) no-repeat center`,
          width: '1.5rem',
          height: '1.5rem'
        },
        "& .events-icon": {
          backgroundColor: "#fff",
          '-webkit-mask': `url(${eventsIcon}) no-repeat center`,
          'mask': `url(${eventsIcon}) no-repeat center`,
          width: '1.5rem',
          height: '1.5rem'
        },
        "& .reminder-icon": {
          backgroundColor: "#fff",
          '-webkit-mask': `url(${reminderIcon}) no-repeat center`,
          'mask': `url(${reminderIcon}) no-repeat center`,
          width: '1.5rem',
          height: '1.5rem'
        },
        "& .appointments-icon": {
          backgroundColor: "#fff",
          '-webkit-mask': `url(${appointmentsIcon}) no-repeat center`,
          'mask': `url(${appointmentsIcon}) no-repeat center`,
          width: '1.5rem',
          height: '1.5rem'
        },
        "& .group-icon": {
          backgroundColor: "#fff",
          '-webkit-mask': `url(${group3Icon}) no-repeat center`,
          'mask': `url(${group3Icon}) no-repeat center`,
          width: '1.5rem',
          height: '1.5rem'
        },
        "& .chat-icon": {
          backgroundColor: "#fff",
          '-webkit-mask': `url(${chatIcon}) no-repeat center`,
          'mask': `url(${chatIcon}) no-repeat center`,
          width: '1.5rem',
          height: '1.5rem'
        },
        "& .contact-us-icon": {
          backgroundColor: "#fff",
          '-webkit-mask': `url(${contactUsIcon}) no-repeat center`,
          'mask': `url(${contactUsIcon}) no-repeat center`,
          width: '1.5rem',
          height: '1.5rem'
        }
      }
    },
    [`@media screen and (max-width: 960px)`]: {
      width: '5rem',
      padding: "1rem 0rem 1rem 0rem",
      "& .nav-menu-items": {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        "& .nav-menu-item": {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '50%',
          padding: '0rem !important',
          width: '3rem',
          height: '3rem',
          "& p": {
            display: 'none'
          }
        }
      }
    },
  }
})

const RightSideNavigationMenuContainer = styled('div')({
  "&&.right-side-navigation-menu": {
    // width:'calc(100% - 14.5rem)',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    background: "rgba(3, 23, 33, 0.6)",
    borderRadius: '1.5rem',
    position: 'relative',
    zIndex: 2,
    "& .toggle-nav-menu-btn": {
      position: 'absolute',
      left: 0,
      top: '0.3rem',
      width: '1.5rem',
      height: '1.5rem',
      borderRadius: '50%',
      transform: "rotate(90deg) translate(50%,50%)",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'linear-gradient(180deg, #23404B 0%, #19282F 100%)',
      'box-shadow': '0px 0px 12.07px 0px #0000001A',
      cursor: 'pointer',
      "&.collapse": {
        transform: 'rotate(-90deg) translate(-50%,-50%)'
      }
    },
    "& .nav-container": {
      padding: '0.5rem 1rem 0.5rem 1.3rem',
      display: 'flex',
      gap: "0.5rem",
      alignItems: "center",
      boxSizing: "border-box",
      "& .search-bar-container": {
        display: 'flex',
        flexGrow: 1,
        gap: "0.3rem",
        alignItems: "center",
        justifyContent: "center",
        "& .search-bar": {
          display: "flex",
          alignItems: "center",
          gap: "0.3rem",
          width: "100%",
          maxWidth: "34.9375rem",
          height: "3rem",
          padding: "0rem 1rem",
          boxSizing: "border-box",
          background: "rgba(35, 64, 75, 1)",
          borderRadius: "3.125rem",
          "& input": {
            width: '100%',
            outline: 'none',
            border: 'none',
            background: 'transparent',
            color: "rgba(255, 255, 255, 0.8)",
            fontSize: '1rem',
            fontWeight: 400,
            "&::placeholder": {
              color: "rgba(255, 255, 255, 0.8)",
              fontSize: '1rem',
              fontWeight: 400
            }
          }
        },
      },
      "& .profile-container": {
        display: 'flex',
        alignItems: 'center',
        gap: "0.5rem",
        cursor: "pointer",
        "& .avatar": {
          width: '2.5rem',
          height: "2.5rem",
          "& img": {
            width: '100%',
            height: '100%'
          }
        },
        "& p": {
          margin: 0,
          fontSize: '1rem',
          fontWeight: 500,
          color: 'white'
        },
        "& > img": {
          marginLeft: '0.3rem',
          "&.open": {
            transform: "rotate(180deg)"
          }
        }
      }
    },
    [`@media screen and (max-width: 960px)`]: {
      "& .search-bar-container": {
        "& .profile-container": {
          "& p": {
            display: 'none'
          }
        }
      },
      "& .toggle-nav-menu-btn": {
        display: 'none'
      }
    }
  }
})

const CustomMenu = styled(Menu)({
  "& .MuiList-padding": {
    padding: 0, // Set padding to 0
  },
  "& .MuiPaper-root": {
    background: "transparent"
  }
});

const ProfileMenuContainer = styled('div')({
  "&.nav-profile-menu": {
    height: '4.875rem',
    width: "12.3125rem",
    background: 'linear-gradient(180deg, #23404B 0%, #19282F 100%)',
    boxShadow: '0px 0px 28px 0px #00000059',
    border: '1px solid',
    borderImageSource: 'linear-gradient(0deg, rgba(28, 150, 154, 0.11) 0%, rgba(25, 181, 185, 0.27) 100%)',
    display: 'flex',
    flexDirection: 'column',
    padding: "0 0.5rem 0 0.5rem",
    justifyContent: 'space-around',
    borderRadius: "0.5rem",
    "& .profile-menu-item": {
      display: 'flex',
      alignItems: 'center',
      gap: "0.4rem",
      cursor: "pointer",
      "& p": {
        margin: 0,
        fontSize: '1rem',
        color: '#fff'
      },
      "& .image-container": {
        width: '1.5rem',
        height: '1.5rem',
        display: 'flex',
        justifyContent: "center",
        alignItems: 'center'
      }
    }
  }
})

const StyledSearchOptions = styled("div")({
  "&.NavigationMenu_StyledSearchOptions":{
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    padding: "0.2rem 0.3rem",
    boxSizing: "border-box",
    cursor: "pointer",
     "& > p":{
      fontSize: "0.875rem",
      fontWeight: 400,
      color: "rgba(215, 215, 215, 1)",
      textWrap: "nowrap"
     }
  }
})

const StyledSearchOptionsMenu = styled("div")({
  "&.NavigationMenu_StyledSearchOptionsMenu":{
    background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
    borderRadius: "0.5rem",
    border: "1px solid rgba(25, 181, 185, 0.27)",
    "& > ul":{
      listStyle: "none",
      padding: "0.2rem 0",
      width: "9.5625rem",
      "& > li":{
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
        padding: "0.3125rem 0.5rem",
        transition: "background-color 0.3s ease-in-out",
        cursor: "pointer",
        fontSize: "1rem",
        fontWeight: 400,
        color: "rgba(255, 255, 255, 1)",
        "&:hover":{
          backgroundColor: "rgba(0, 0, 0, 0.15)"
        }
      }
    }
  }
})
// Customizable Area End
