import React, { useEffect, useState } from "react";
import { styled } from "@material-ui/styles";
import SelectAppointmentAndEvent from "./SelectAppointmentAndEvent.web";
import { Button, InputText, SelectContacts } from "../../../../components/src";
import { ContactList, IAppointmentData, IEventData } from "../../../../components/src/CommonTypes";
import { closeIcon, checkedBoxIcon, emptyCheckBoxIcon } from "../../../appointmentmanagement/src/assets";
import { Dialog } from "@material-ui/core";
const {
    HelperFunctions: helper,
} = require("../../../../components/src/HelperFunctions");
import { LessIcon } from "../assets";

interface Props {
    onClose: () => void;
    appointments?: IAppointmentData[];
    events?: IEventData[];
    contactList?: ContactList[];
    phoneBookContactList?: ContactList[];
    onSearchContact?: (event: string) => void;
    fetchContacts?: () => void;
    onSearch?: (event: string) => void;
    onCreateChat?: (data: any)=> void;
    isFromAppointmentOrEvent?: boolean;
    appointmentOrEventDetail?: IAppointmentData | null;
    type?: "events" | "appointments";
}


const StartNewChatForm: React.FC<Props> = (props: Props) => {
    const {
        onClose,
        appointments,
        events = [],
        contactList = [],
        phoneBookContactList = [],
        onSearchContact,
        onSearch,
        onCreateChat = ()=>{},
        appointmentOrEventDetail,
        isFromAppointmentOrEvent,
        type
    } = props;
    const [selectedContactList, setSelectedContactList] = useState<ContactList[]>([]);
    const [step, setStep] = useState(1);
    const [selectedData, setSelectedData] = useState<IAppointmentData | IEventData | null>(null);
    const [removedContactList, setRemovedContactList] = useState<ContactList[]>([]);
    const [chatName, setChatName] = useState("")

    const onSearchContactHandler = (event: string) => {
        if (onSearchContact) {
            onSearchContact(event)
        }
    }

    const onAddContacts = (contacts: ContactList[]) => {
        const updatedList = [...selectedContactList, ...contacts]
        setSelectedContactList(updatedList);
        setChatName(getChatName(updatedList));
        onNext();
    }

    const findItemPresentInEmailAndContactList = (accountId: number | string) => {
        return selectedContactList.find(contact => contact.id === accountId) || selectedContactList.find(contact => contact.full_phone_number === accountId);
    }

    const toggleSelectedContact = (contact: ContactList) => {
        if (removedContactList.find(item => item.id === contact.id)) {
            const updatedList = removedContactList.filter(item => item.id !== contact.id);
            setRemovedContactList(updatedList)
        } else {
            setRemovedContactList(prevState => ([...prevState, contact]));
        }
    }

    const onPrev = () => {
        if (step === 3) {
            setSelectedContactList(prevState => {
                return prevState.filter(contact => !removedContactList.find(item => item.id === contact.id))
            })
            setRemovedContactList([]);
        }
        setStep(step - 1);
    }

    const onNext = () => {
        setStep(step + 1);
    }

    const getChatName = (contacts: ContactList[]) => {
        let chat_name = "";
        if (selectedData) {
            chat_name += `${selectedData.attributes.title};`
        }
        if (contacts.length) {
            const names: string[] = [];
            contacts.forEach(contact => {
                names.push(contact.name.split(" ")[0])
            })
            chat_name += names.join(",")
        }
        return chat_name;
    }

    const AddChat = () => {
        return (
            <Dialog
                open={true}
                onClose={onClose}
                PaperProps={{
                    style: {
                        background: "transparent"
                    }
                }}
            >
                <StyledAddChat className="GroupChat_StyledAddChat">
                    <div className="header">
                        <img className="back" src={LessIcon} onClick={onPrev} />
                        <p>Add Chat</p>
                        <img className="close" src={closeIcon} alt="close.svg" onClick={onClose} />
                    </div>
                    {
                        selectedData && (
                            <div className="body">
                                <div className="chat-image">
                                    {helper.getInitials(selectedData.attributes.title)}
                                </div>
                                <div className="chat-name-field">
                                    <InputText
                                        value={chatName}
                                        onChange={(event) => setChatName(event.target.value)}
                                        label="Chat Name"
                                        type="text"
                                    />
                                </div>
                                <div className="invited-person-list">
                                    <p>Invited</p>
                                    <ul>
                                        {
                                            selectedContactList.map(contact => {
                                                return (
                                                    <li key={contact.id}>
                                                        <div className="left">
                                                            <div className="avatar-text">
                                                                {contact.name[0]}
                                                            </div>
                                                            <p>{contact.name}</p>
                                                        </div>
                                                        <div className="right">
                                                            {
                                                                removedContactList.find(item => item.id === contact.id) ? (
                                                                    <img src={emptyCheckBoxIcon} onClick={() => toggleSelectedContact(contact)} />
                                                                ) : (
                                                                    <img src={checkedBoxIcon} onClick={() => toggleSelectedContact(contact)} />
                                                                )
                                                            }
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        )
                    }
                    <div className="footer">
                        <Button
                            text="Create"
                            disabled={selectedContactList.length === removedContactList.length || !chatName.trim()}
                            buttonStyles={{
                                background: "linear-gradient(180deg, #F37F78 0%, #F75367 100%)",
                                width: "100%"
                            }}
                            onClick={onCreateChatHandler}
                        />
                    </div>
                </StyledAddChat>
            </Dialog>
        )
    }

    const onCreateChatHandler = async () => {
        const userData = await helper.getUserData();
        let postData = {};
        if (userData) {
            postData = {
                name: chatName.trim(),
                type: "public",
                members: {
                    admins: [userData.id],
                    participants: selectedContactList.map(contact=> contact.id)
                }
            }
        }
        onCreateChat(postData);
    }

    useEffect(() => {
        if(isFromAppointmentOrEvent && appointmentOrEventDetail){
            setStep(2);
            setSelectedData(appointmentOrEventDetail)
        }
    }, [])

    return (
        <>
            {
                step === 1 && (
                    <SelectAppointmentAndEvent
                        onClose={onClose}
                        appointments={appointments}
                        onSelectData={(event) => {
                            setSelectedData(event);
                            onNext();
                        }}
                        onSearch={onSearch}
                        events={events}
                        type={type}
                    />
                )
            }
            {
                step === 2 && (
                    <SelectContacts
                        open={true}
                        onClose={onClose}
                        contacts={contactList}
                        phoneBookContacts={phoneBookContactList}
                        filterContacts={selectedContactList}
                        onSearch={onSearchContactHandler}
                        onAdd={onAddContacts}
                        alreadySelected={findItemPresentInEmailAndContactList}
                        enableBack={isFromAppointmentOrEvent ? false : true}
                        onClickBack={onPrev}
                        header="Select Member"
                    />
                )
            }
            {
                step === 3 && (
                    AddChat()
                )
            }
        </>
    )
}

const StyledAddChat = styled("div")({
    "&.GroupChat_StyledAddChat": {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#23404B",
        width: "26.25rem",
        height: "34.3125rem",
        boxShadow: "0px 0px 20px 0px #00000040",
        borderRadius: "1rem",
        "& > .header": {
            position: "relative",
            padding: "1rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderBottom: "1px solid #2d4953",

            "& p": {
                margin: 0,
                fontWeight: 500,
                color: "white",
                fontSize: "1.125rem"
            },
            "& img.close": {
                cursor: "pointer",
                position: "absolute",
                right: "1rem",
                top: "50%",
                transform: "translate(-50%,-50%)",
            },
            "& img.back": {
                cursor: "pointer",
                position: "absolute",
                left: "1rem",
                top: "50%",
                transform: "translate(50%,-50%)",
            }
        },
        "& > .body": {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            overflow: "auto",
            gap: "0.5rem",
            padding: "1rem",
            "& > .chat-image": {
                width: "6.5rem",
                height: "6.5625rem",
                background: "rgba(0, 0, 0, 0.2)",
                borderRadius: "1rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textTransform: "uppercase",
                alignSelf: "center",
                fontSize: "2.5rem",
                fontWeight: 400,
                color: "rgba(255, 255, 255, 0.8)",
                marginBottom: "1rem",
                flexShrink: 0
            },
            "& > .invited-person-list": {
                "& > p": {
                    fontSize: "0.75rem",
                    fontWeight: 500,
                    color: "rgba(255, 255, 255, 1)",
                    marginTop: "1rem"
                },
                "& > ul": {
                    listStyle: "none",
                    padding: 0,
                    "& > li": {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        height: "2.75rem",
                        "& > .left": {
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                            "& > p": {
                                fontSize: "0.875rem",
                                fontWeight: 400,
                                color: "rgba(255, 255, 255, 1)"
                            }
                        },
                        "& > .right > img": {
                            cursor: "pointer"
                        }
                    }
                }
            }
        },
        "& .footer": {
            padding: "1rem",
        },
    }
})

export default StartNewChatForm;