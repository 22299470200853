import React from "react";
// Customizable Area Start
import {
  styled,
  Modal,
  Box,
  CircularProgress,
  Typography
} from "@material-ui/core";
import { StylesProvider, createGenerateClassName, ThemeProvider, createTheme } from '@material-ui/core/styles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import Appointment from "./components/Appointment.web";
import SearchField from "../../groups/src/components/SearchField.web";
import { Button } from "../../../components/src";
import ViewGroupChat from "../../chat/src/ViewGroupChat.web";
import StartNewChatForm from "../../chat/src/components/StartNewChatForm.web";
import { chatEditIcon } from "./assets";
const {
  HelperFunctions: helper,
} = require("../../../components/src/HelperFunctions");

const generateClassName = createGenerateClassName({
  productionPrefix: 'AppointmentDetail',
  disableGlobal: false,
});

const theme = createTheme(); 
// Customizable Area End

import AppointmentDetailsController, {
  Props
} from "./AppointmentDetailsController";

export default class AppointmentDetails extends AppointmentDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  DeleteModal = () => {
    const { isDeleteModalOpen, meetingTitle } = this.state;
    return (
      <Modal
       open={isDeleteModalOpen}
       onClose={this.closeDeleteModal}
       style={{display:"flex",justifyContent:"center",alignItems:"center"}}
      >
        <StyledDeleteModal>
          <p>Delete Appointment?</p>
          <p>Are you sure that you want to delete {meetingTitle}?</p>
          <div className="actions">
            <button id="delete-btn" onClick={this.onDeleteAppointment}>Yes</button>
            <button id="cancel-btn" onClick={this.closeDeleteModal}>No</button>
          </div>
        </StyledDeleteModal>
      </Modal>
    )
  }

  ChatsList = () => {
    const {
      chatSearchString,
      chatList,
      isChatListOpen,
      isChatLoading
    } = this.state;
    return (
      <StyledAppointmentBottomChat className={`AppointmentChat_StyledAppointmentBottomChat ${!isChatListOpen ? "isMinimized" : ""} ${chatList.length > 0 ? "chatPresent" : "noChat"}`}>
        <Box className="header">
          <Box className="header-chats" display={"flex"} alignItems={"center"} gridGap={"8px"}>
            <p>Chats</p>
            {!isChatListOpen && <Typography component={"span"}>
              {`(${chatList.length})`}
            </Typography>}
          </Box>
          <Box gridGap={"8px"} display={"flex"} alignItems={"center"}>
            {isChatListOpen && <img onClick={() => this.openChatCreate()} className="cursor-pointer" id="create-new-chat" src={chatEditIcon} alt="edit-chat" />}
            {isChatListOpen ? <ExpandMoreIcon id="toggle-chatlist" onClick={this.toggleChatList} /> : <ExpandLessIcon id="toggle-chatlist" onClick={() => this.toggleChatList()} />}
          </Box>
        </Box>
        {isChatListOpen &&
          <>
          {isChatLoading && <Box id="appointment-chat-loader" className="common-circular-progress-loader">
            <CircularProgress />
          </Box>}
          {chatList.length > 0 ? <>
              <Box className="appointment-chat-search-field">
                <SearchField
                  onChange={this.onChangeSearchHandler}
                  value={chatSearchString}
                  placeholder="Search group name"
                />
              </Box>
              <Box className="chats">
                {
                  chatList.map(chat => {
                    return (
                      <Box id={`chat-${chat.comet_response.guid}`} key={chat.comet_response.guid} className={`chat`} onClick={() => this.setOpenChat(chat.comet_response)}>
                        <Box className="chat-name">
                          <Box className="chat-image">
                            {helper.getInitials(chat.comet_response.name)}
                          </Box>
                          <p>{chat.comet_response.name.length > 20 ? `${chat.comet_response.name.slice(0, 20)}...` : chat.comet_response.name}</p>
                        </Box>
                      </Box>
                    )
                  })}
              </Box>
            </>
              :
              <>
               {!isChatLoading && <div className="no-chats">
                  <p>Send messages to the friend or group</p>
                  <Button
                    text="Start New Chat"
                    type="outlined"
                    buttonStyles={{
                      height: "2.25rem",
                    }}
                    onClick={this.openChatCreate}
                  />
                </div>}
              </>
            }
          </>
        }
      </StyledAppointmentBottomChat>
    )
  }

  OpenChatsList = () => {
    const {
      openChatList,
    } = this.state;
    return (
      <StyledOpenChatContainer className="AppointmentOpenChats_WebContainer">
        {openChatList.length > 0 && openChatList.map((openChat) =>
          <StyledAppointmentBottomChat id={`openchat-${openChat.guid}`} key={openChat.guid} style={{ position: "relative", width: "400px", padding: openChat.isOpen ? "0px" : "" }} className={`AppointmentChat_StyledAppointmentBottomChat chatRoom ${!openChat.isOpen ? "isMinimized" : ""}`}>
            {!openChat.isOpen ?
              <Box className="header">
                <Box className="chat-name-header">
                  <Box className="chat-header-image">
                    {helper.getInitials(openChat.name)}
                  </Box>
                  <p>{openChat.name.length > 25 ? `${openChat.name.slice(0, 25)}...` : openChat.name}</p>
                </Box>
                <Box>
                  <ExpandLessIcon id="open_chat_room" onClick={() => this.setOpenSelectedChat(openChat.guid)} />
                </Box>
              </Box> : <ViewGroupChat closeGroupChatPopup={this.setCloseSelectedChat} isFromAppointmentOrEvent id="View Chat" navigation={this.props.navigation} />
            }
          </StyledAppointmentBottomChat>
        )}
      </StyledOpenChatContainer>
    )
  }

  AppointmentsComponent = () => {
    const { appointment, isRsvpStatusUpdateMode, invitedUserList, activeTab, optionAnchorEle } = this.state;
    return (
      <StyledAppointmentsContainer className="AppointmentDetailContainer_web">
        <div className="back-btn" onClick={this.goBack}>
          <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.99953 11.6553L0.345703 6.00148L5.99953 0.347656L7.05335 1.40148L2.45335 6.00148L7.05335 10.6015L5.99953 11.6553Z" fill="white" />
          </svg>
          Details
        </div>
        {
          appointment && (
            <Appointment
              redirectTo={this.redirectTo}
              appointment={appointment}
              onUpdateRsvpStatus={this.onUpdateRsvpStatus}
              setInvitedUsers={this.setInvitedUsersForAppointmentDetails}
              onRsvpUpdateModeChange={this.onRsvpUpdateModeChange}
              activeTab={activeTab}
              closeUserListModal={this.closeUserListModal}
              setActiveTab={this.setActiveTab}
              isRsvpStatusUpdateMode={isRsvpStatusUpdateMode}
              invitedUserList={invitedUserList}
              openOptionMenu={this.openOptionMenu}
              optionDropdownMenuAnchorEle={optionAnchorEle}
              openDeleteModal={this.openDeleteModal}
            />
          )
        }
        {this.DeleteModal()}
      </StyledAppointmentsContainer>
    )
  }

  DetailMainContainer = () => {
    const { openChatCreateForm, contactList, phoneBookContactList, appointment } = this.state;
    return (
      <StylesProvider generateClassName={generateClassName}>
         <ThemeProvider theme={theme}>
          <StyledDetailMainContainer className="DetailAppointment_WebContainer">
            {this.AppointmentsComponent()}
            {this.ChatsList()}
            {this.OpenChatsList()}
            {
              openChatCreateForm && (
                <StartNewChatForm
                  onClose={this.closeChatCreate}
                  appointments={[]}
                  isFromAppointmentOrEvent
                  appointmentOrEventDetail={appointment}
                  onSearchContact={this.onSearchContact}
                  contactList={contactList}
                  phoneBookContactList={phoneBookContactList}
                  onSearch={() => {}}
                  onCreateChat={this.onCreateAppointmentChat}
                />
              )
            }
          </StyledDetailMainContainer>
         </ThemeProvider>
       </StylesProvider>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <NavigationMenu {...this.props} children={this.DetailMainContainer()} />
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledAppointmentsContainer = styled("div")({
  "&.AppointmentDetailContainer_web": {
    display: "flex",
    flexDirection: "column",
    padding: "1rem 1rem",
    gap: "1.5rem",
    "& .ellipsis": {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden"
    },
    "& > .back-btn": {
      fontSize: "1.125rem",
      fontWeight: 500,
      color: "white",
      display: "flex",
      gap: "0.5rem",
      alignItems: "center",
      cursor: "pointer",
      width: "fit-content"
    },
  }
})
const StyledDeleteModal = styled("div")({
  display:"flex",
  flexDirection:"column",
  alignItems:"center",
  justifyContent:"space-around",
  background: "linear-gradient(0deg, #23404B, #23404B)",
  borderRadius:"1.5rem",
  width:"20.4375rem",
  height:"12.125rem",
  padding:"1rem 2rem",
  boxSizing:"border-box",
  "& p":{
    fontSize:"0.875rem",
    fontWeight:500,
    color:"white",
    textAlign:"center",
    margin:0,
    "&:first-child":{
      fontSize:"1.125rem"
    }
  },
  "& .actions":{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    gap:"1rem",
    "& button":{
      border:"none",
      outline:"none",
      width:"8.6875rem",
      padding:"1rem 0rem",
      fontSize:"1rem",
      fontWeight:"500",
      borderRadius:"62.5rem",
      cursor:"pointer",
      "&:first-child":{
        background: "linear-gradient(180deg, #F37F78 0%, #F75367 100%)",
        color:"white"
      },
      "&:last-child":{
        border: "1px solid #F75367",
        color:"#F75367",
        backgroundColor:"transparent"
      }
    }
  }
})
const StyledAppointmentBottomChat = styled("div")({
  "&.AppointmentChat_StyledAppointmentBottomChat": {
    boxShadow: "0px 0px 28px 0px rgba(0, 0, 0, 0.35)",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    boxSizing: "border-box",
    padding: "12px 0px 0px",
    position: "absolute",
    bottom: 1,
    width: "350px",
    background: "rgba(35, 64, 75, 1)",
    borderRadius: "0.5rem",
    zIndex: 10,
    transitionDuration: "167ms",
    animation: "fade-in 167ms cubic-bezier(.4,0,1,1)",
    "&.chatPresent": {
      height: "calc(100vh - 164px)",
      right: "15px",
    },
    "&.chatRoom": {
      height: "calc(100vh - 255px)",
    },
    "&.noChat": {
      right: "15px",
      height: "calc(100vh - 320px)",
    },
    [`@media screen and (max-width: 600px)`]: {
      width: "100%",
    },
    "&.isMinimized": {
      transform: "translateY(100%) translateY(-48px) !important",
      height: "48px !important",
      borderTopRightRadius: "0.5rem",
      borderTopLeftRadius: "0.5rem",
      borderBottomLeftRadius: "0px !important",
      borderBottomRightRadius: "0px !important"
    },
    "& > .header": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexWrap: "wrap",
      "& > .header-chats": {
        "& > p": {
          fontSize: "1.25rem",
          fontWeight: 500,
          color: "rgba(255, 255, 255, 1)",
        },
        "& > span": {
          fontSize: "1.25rem",
          fontWeight: 500,
          color: "rgba(255, 255, 255, 1)",
        },
      },
      "& > .chat-name-header": {
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
        "& > .chat-header-image": {
          background: "rgba(0, 0, 0, 0.2)",
          width: "2rem",
          height: "2rem",
          borderRadius: "0.5rem",
          justifyContent: "center",
          alignItems: "center",
          textTransform: "uppercase",
          fontSize: "15px",
          fontWeight: 400,
          display: "flex",
          color: "rgba(255, 255, 255, 0.8)"
        },
        "& > p": {
          fontSize: "1rem",
          fontWeight: 500,
          color: "rgba(255, 255, 255, 1)",
          whiteSpace: "nowrap",      
          overflow: "hidden",        
          textOverflow: "ellipsis",  
          maxWidth: "250px",
          [`@media screen and (max-width: 960px)`]: {
            maxWidth: "10%",
          },
        }
      },
      "& svg": {
        color: "#fff",
        cursor: "pointer",
      },
      padding: "0rem 1.5rem",
      boxSizing: "border-box",
    },
    "& > .appointment-chat-search-field": {
      boxSizing: "border-box",
      padding: "0rem 1.5rem",
    },
    "& > .chats": {
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
      "& > .chat": {
        justifyContent: "space-between",
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        padding: "0.75rem 1.5rem",
        cursor: "pointer",
        "& > .chat-name": {
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          "& > .chat-image": {
            background: "rgba(0, 0, 0, 0.2)",
            width: "3.3125rem",
            height: "3.3125rem",
            borderRadius: "0.5rem",
            justifyContent: "center",
            alignItems: "center",
            textTransform: "uppercase",
            fontSize: "1.5rem",
            fontWeight: 400,
            display: "flex",
            color: "rgba(255, 255, 255, 0.8)"
          },
          "& > p": {
            fontSize: "1rem",
            fontWeight: 500,
            color: "rgba(255, 255, 255, 1)"
          }
        },
        "&:hover": {
          background: "rgba(0, 0, 0, 0.15)"
        },
      },
    },
    "& > .no-chats": {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      flexDirection: "column",
      gap: "0.5rem",
      "& > p": {
        fontSize: "1rem",
        fontWeight: 400,
        color: "rgba(255, 255, 255, 0.8)"
      }
    }
  }
})
const StyledDetailMainContainer  = styled("div")({
  "&.DetailAppointment_WebContainer": {
    height: "100%",
    [`@media screen and (max-width: 600px)`]: {
      height: "max-content",
    },
  }
})
const StyledOpenChatContainer  = styled("div")({
  "&.AppointmentOpenChats_WebContainer": {
    overflow: "scroll",
    gap: "16px",
    position: "absolute",
    bottom: 0,
    right: "381px",
    display: "flex",
    alignItems: "end",
    maxWidth: "calc(100vw - 41rem)",
    [`@media screen and (max-width: 768px)`]: {
      width: "calc(100vw - 32rem)",
    },
  }
})
// Customizable Area End
