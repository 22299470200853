import React from "react";
import MomentUtils from "@date-io/moment";
import { Message } from "../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import { getStorageData, setStorageData, removeStorageData } from "../../framework/src/Utilities";
import { toast } from "react-toastify";
import moment from "moment";
import { toArray } from "react-emoji-render";
import Toaster from "./Toaster.web";

export const HelperFunctions = {
    formatDate: (value, format) => {
        const moment = new MomentUtils();
        const formattedDate = moment.date(value).format(format)
        return formattedDate;
    },
    isToday: (dateStr) => {
        const moment = new MomentUtils();
        const date = moment.date(dateStr);
        return date.isSame(moment(), 'day')
    },
    apiCall: async (data) => {
        let { method, endPoint, body, type = "", contentType, token, apiKey } = data;
        const header = {
            token: token,
            "Content-Type": contentType,
            "apiKey": apiKey
        };
        let apiRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        apiRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
        apiRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        apiRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
        apiRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
        body && type !== "formData"
            ? apiRequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body))
            : apiRequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
        runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
        return apiRequestMessage.messageId;
    },
    copy: (text) => {
        navigator?.clipboard?.writeText(text);
    },
    convertToBase64: (event) => {
        return new Promise((resolve) => {
            // Create a FileReader instance
            const reader = new FileReader();

            // Set up onload event
            reader.onload = (event) => {
                // e.target.result contains the Base64-encoded image data
                const base64Image = event.target.result;

                // Construct the data URL
                // Now you can use 'dataUrl' to display the image
                resolve(base64Image);

            };
            reader.onerror = (event) => {
                resolve(event.target.error)
            };

            // Read the image file as a data URL (this triggers the onload event)
            reader.readAsDataURL(event.target.files[0]);
        })
    },
    covertToObjectUrl: (event) => {
        return new Promise((resolve) => {
            const validTypes = ['.png', '.jpg'];
            const files = event.target.files;
            if (files && files.length > 0) {
                if (files[0].size > 10 * 1024 * 1024) {
                    resolve({ imgSizeExceed: true });
                } else if (!validTypes.find(format => files[0].name.includes(format))) {
                    resolve({ imgSizeExceed: true });
                } else {
                    // Read the image file as a data URL (this triggers the onload event)
                    const reader = new FileReader();
                    reader.readAsDataURL(files[0]);
                    let imgUrl = URL.createObjectURL(files[0]);
                    resolve({ selectedImage: imgUrl, imgSizeExceed: false });
                }
            }
        })
    },
    covertFileToObjectUrl: (file) => {
        return new Promise((resolve) => {
            const validImagePdfTypes = ['.png', '.jpg', ".pdf"];
            const validVideoTypes = [".mp4"];
            const validTypes = [...validImagePdfTypes, ...validVideoTypes];
            if (file) {
                if ((file.type.includes("image") && file.size > 2 * 1024 * 1024) || (file.type.includes("pdf") && file.size > 2 * 1024 * 1024) || (file.type.includes("video") && file.size > 10 * 1024 * 1024)) {
                    resolve({ imgInvalid: true });
                } else if (!validTypes.find(format => file.name.includes(format))) {
                    resolve({ imgInvalid: true });
                } else {
                    // Read the image file as a data URL (this triggers the onload event)
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    let imgUrl = URL.createObjectURL(file);
                    resolve({ selectedUrl: imgUrl, imgInvalid: false, type: file.type });
                }
            }
        })
    },
    isStartTimeLessThanEndTime: (startTime, endTime) => {
        // Create Date objects for the start and end times
        const startDate = new Date(`1970-01-01T${convertTo24HourFormat(startTime)}:00`);
        const endDate = new Date(`1970-01-01T${convertTo24HourFormat(endTime)}:00`);

        // Compare the times
        return endDate < startDate;
    },
    isStartDateLessThanEndDate: (startDate, endDate) => {
        const start = moment(startDate, "YYYY-MM-DD"); // Adjust the format based on the date string format
        const end = moment(endDate, "YYYY-MM-DD");
    
        // Check if start date is before end date
        return start.isBefore(end);
    },
    isEqualTime: (startTime, endTime) => {
        // Create Date objects for the start and end times
        const startDate = new Date(`1970-01-01T${convertTo24HourFormat(startTime)}:00`);
        const endDate = new Date(`1970-01-01T${convertTo24HourFormat(endTime)}:00`);

        // Compare the times
        return endDate === startDate;
    },
    getUserData: async () => {
        return await getStorageData("userData", true);
    },
    isPastTime: (timeString) => {
        // Parse the time string into a Date object
        const [time, period] = timeString.split(" ");
        let [hours, minutes] = time.split(":").map(Number);

        if (period === "PM" && hours !== 12) {
            hours += 12;
        }
        if (period === "AM" && hours === 12) {
            hours = 0;
        }

        const now = new Date();
        const givenTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes);

        return givenTime < now;
    },
    isPastDate: (dateString) => {
        const date = moment(dateString, "YYYY-MM-DD hh:mm A");
        // Compare the date with the current date
        return date.isBefore(moment());
    },
    isPastRoutine: (startDate, startTime, endDate, endTime) => {
        if(endDate && endTime){
            const date = moment(`${endDate} ${endTime}`, "YYYY-MM-DD hh:mm A");
            return date.isBefore(moment());
        }else if(endDate){
            const date = moment(`${endDate} 11:59 PM}`, "YYYY-MM-DD hh:mm A");
            return date.isBefore(moment());
        }else if(startDate && startTime){
            const date = moment(`${startDate} ${startTime}`, "YYYY-MM-DD hh:mm A");
            return date.isBefore(moment());
        }else if(startDate){
            const date = moment(`${startDate} 11:59 PM}`, "YYYY-MM-DD hh:mm A");
            return date.isBefore(moment());
        }
        // Compare the date with the current date
        return false;
    },
    numberSanitization: (value) => {
        return value.replace(/^0+|[^\d.]/g, '');
    },
    showLoader: () => {
        document.getElementById("custom-loader-main").style.display = "block";
        document.getElementById("custom-loader-main").style.backgroundColor = "rgb(1 14 22)";
        document.getElementById("custom-loader-main").style.opacity = 0.9;
        const isLoaderDivInDocument = document.getElementsByClassName("no-data");
        const isLoaderInDocument = document.getElementById("no-data-component");
        if (isLoaderInDocument) {
            document.getElementById("no-data-component").style.display = "none";
        }
        if (isLoaderDivInDocument.length) {
            for (let i = 0; i < isLoaderDivInDocument.length; i++) {
                isLoaderDivInDocument[i].style.display = "none";
            }
        }
    },
    hideLoader: () => {
        document.getElementById("custom-loader-main").style.display = "none";
        document.getElementById("custom-loader-main").style.backgroundColor = "#0F2631";
        const isLoaderDivInDocument = document.getElementsByClassName("no-data");
        const isLoaderInDocument = document.getElementById("no-data-component");
        if (isLoaderInDocument) {
            document.getElementById("no-data-component").style.display = "flex";
        }
        if (isLoaderDivInDocument.length) {
            for (let i = 0; i < isLoaderDivInDocument.length; i++) {
                isLoaderDivInDocument[i].style.display = "block";
            }
        }
    },
    showSuccessToast: (message) => {
        toast.success(<Toaster message={message} type="success" />);
    },
    showErrorToast: (message) => {
        toast.error(<Toaster message={message} type="error" />);
    },
    isValidEmail: (email) => {
        // Regular expression for validating an Email
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);
    },
    setStorageData: async (key, data) => {
        return await setStorageData(key, data)
    },
    getStorageData: async (key, parseToJson = false) => {
        return await getStorageData(key, parseToJson)
    },
    removeStorageData: async (key) => {
        return await removeStorageData(key)
    },
    logout: async () => {
        await removeStorageData("authToken");
        await removeStorageData("userData");
        await removeStorageData("isNavBarCollapsed");
    },
    openFileInNewTab: (fileUrl) => {
        window.open(fileUrl, "_blank")
    },
    uploadFilesToServer: async (data) => {
        let {
            body,
            type = "",
            token,
            endPoint = "bx_block_upload_media/upload_media/direct_upload",
            method = "POST",
            contentType
        } = data;
        const header = {
            token: token,
            "Content-Type": contentType,
        };
        let apiRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        apiRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
        apiRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        apiRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
        body && type !== "formData"
            ? apiRequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body))
            : apiRequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
        runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
        return apiRequestMessage.messageId;
    },
    addDateAndTime: (date, time, format) => {
        const moment = new MomentUtils();
        const momentDate = moment.date(date + " " + time).format(format);
        return momentDate;
    },
    getFileTypeFromURL: (url) => {
        if (url.includes("type=image")) {
            return "image";
        } else if (url.includes("type=application")) {
            return "pdf";
        }
        return "video";
    },
    getMenuPosition: (event, size) => {
        const buttonRect = event.currentTarget.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        // Check if there's enough space below the button

        if (windowHeight - buttonRect.bottom < size) { // Approximate height of the menu
            return { vertical: 'top', horizontal: 'left' }
        } else {
            return { vertical: 'bottom', horizontal: 'left' }
        }
    },
    getDateFromString: (date, year) => {
        return moment(`${date} ${year}`).toDate();
    },
    getInitials: (title) => {
        const words = title.split(" ");
        if (words.length > 1) {
            return words.slice(0, 2).map(item => item[0]).join(""); // Get the first letter of the first two words
        } else {
            return words[0].slice(0, 2); // If there's only one word, return the first two letters of that word
        }
    },
    replaceUsernamesWithStyling: (text, handleMentionedUserClick) => {
        const regex = /##\s*\{id:\s*(\d+),\s*user_name:\s*([^,}]+)\s*[^}]*\}\s*##/g;

        // Store the matches and plain text
        let lastIndex = 0;
        const elements = [];

        text.replace(regex, (match, id, userName, offset) => {
            // Push plain text before the match
            if (lastIndex < offset) {
                elements.push(<span key={`${lastIndex}-text`}>{text.slice(lastIndex, offset)}</span>);
            }
            // Push the styled username span
            elements.push(
                <span
                    key={id}
                    data-id={id}
                    style={{ color: 'rgba(247, 83, 103, 1)', fontWeight: 'bold', cursor: 'pointer' }}
                >
                    {userName}
                </span>
            );
            lastIndex = offset + match.length; // Update last index
            return match; // Returning match for regex replace
        });

        // Push the remaining text after the last match
        if (lastIndex < text.length) {
            elements.push(<span key={`${lastIndex}-text`}>{text.slice(lastIndex)}</span>);
        }

        return elements;
    },
    parseEmojis: (value) => {
        const emojisArray = toArray(value);

        // toArray outputs React elements for emojis and strings for other
        const newValue = emojisArray.reduce((previous, current) => {
            if (typeof current === "string") {
                return previous + current;
            }
            return previous + current.props.children;
        }, "");

        return newValue;
    },
    getParamFromRoute: (navigation, param) => {
        return navigation?.getParam(param);
    },
    calculatedRemainingTime: (last_attempt_at)=>{
        const lastAttempt = moment(last_attempt_at);
        const fiveMinutesLater = lastAttempt.add(5, 'minutes');
        const now = moment();

        const timeLeft = fiveMinutesLater.diff(now);
        const minutes = Math.floor(timeLeft / (1000 * 60));
        const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
        let formattedTime = "";
        if(minutes > 1){
            formattedTime += `${minutes} minutes`
        }if(minutes === 1){
            formattedTime += "1 minute";
        }

        if(seconds > 1){
            formattedTime += ` ${seconds} seconds`;
        }else if(seconds === 1){
            formattedTime += ` 1 second`;
        }

        return {
            formattedTime: formattedTime,
            timeLeft: timeLeft
        }
    }
}

export const convertTo24HourFormat = (time) => {
    const [hours, minutes, modifier] = time.match(/(\d+):(\d+) ([APM]+)/).slice(1);
    let newHours = parseInt(hours, 10);

    if (modifier === "PM" && newHours < 12) {
        newHours += 12;
    } else if (modifier === "AM" && newHours === 12) {
        newHours = 0;
    }

    return `${String(newHours).padStart(2, '0')}:${minutes}`;
}


export const locationData = {
    message: "Location fetched successfully.",
    predictions: [
        {
            description: "Dallas, TX, USA",
            matched_substrings: [
                {
                    length: 2,
                    offset: 0
                }
            ],
            place_id: "ChIJS5dFe_cZTIYRj2dH9qSb7Lk",
            reference: "ChIJS5dFe_cZTIYRj2dH9qSb7Lk",
            structured_formatting: {
                main_text: "Dallas",
                main_text_matched_substrings: [
                    {
                        length: 2,
                        offset: 0
                    }
                ],
                secondary_text: "TX, USA"
            },
            terms: [
                {
                    offset: 0,
                    value: "Dallas"
                },
                {
                    offset: 8,
                    value: "TX"
                },
                {
                    offset: 12,
                    value: "USA"
                }
            ],
            types: [
                "locality",
                "political",
                "geocode"
            ]
        },
        {
            description: "Dayton, OH, USA",
            matched_substrings: [
                {
                    length: 2,
                    offset: 0
                }
            ],
            place_id: "ChIJAxTdrtWAQIgR5EwO8pLjQKY",
            reference: "ChIJAxTdrtWAQIgR5EwO8pLjQKY",
            structured_formatting: {
                main_text: "Dayton",
                main_text_matched_substrings: [
                    {
                        length: 2,
                        offset: 0
                    }
                ],
                secondary_text: "OH, USA"
            },
            terms: [
                {
                    offset: 0,
                    value: "Dayton"
                },
                {
                    offset: 8,
                    value: "OH"
                },
                {
                    offset: 12,
                    value: "USA"
                }
            ],
            types: [
                "locality",
                "political",
                "geocode"
            ]
        },
        {
            description: "Daytona Beach, FL, USA",
            matched_substrings: [
                {
                    length: 2,
                    offset: 0
                }
            ],
            place_id: "ChIJg1YCJZTb5ogR6yrLHbc7ajY",
            reference: "ChIJg1YCJZTb5ogR6yrLHbc7ajY",
            structured_formatting: {
                main_text: "Daytona Beach",
                main_text_matched_substrings: [
                    {
                        length: 2,
                        offset: 0
                    }
                ],
                secondary_text: "FL, USA"
            },
            terms: [
                {
                    offset: 0,
                    value: "Daytona Beach"
                },
                {
                    offset: 15,
                    value: "FL"
                },
                {
                    offset: 19,
                    value: "USA"
                }
            ],
            types: [
                "locality",
                "political",
                "geocode"
            ]
        },
        {
            description: "Danbury, CT, USA",
            matched_substrings: [
                {
                    length: 2,
                    offset: 0
                }
            ],
            place_id: "ChIJ75gTLYRV3YkR7dNTBAuOwaE",
            reference: "ChIJ75gTLYRV3YkR7dNTBAuOwaE",
            structured_formatting: {
                main_text: "Danbury",
                main_text_matched_substrings: [
                    {
                        length: 2,
                        offset: 0
                    }
                ],
                secondary_text: "CT, USA"
            },
            terms: [
                {
                    offset: 0,
                    value: "Danbury"
                },
                {
                    offset: 9,
                    value: "CT"
                },
                {
                    offset: 13,
                    value: "USA"
                }
            ],
            types: [
                "locality",
                "political",
                "geocode"
            ]
        },
        {
            description: "Dalton, GA, USA",
            matched_substrings: [
                {
                    length: 2,
                    offset: 0
                }
            ],
            place_id: "ChIJj47ynNZ0YIgRULdClZJ3iSU",
            reference: "ChIJj47ynNZ0YIgRULdClZJ3iSU",
            structured_formatting: {
                main_text: "Dalton",
                main_text_matched_substrings: [
                    {
                        length: 2,
                        offset: 0
                    }
                ],
                secondary_text: "GA, USA"
            },
            terms: [
                {
                    offset: 0,
                    value: "Dalton"
                },
                {
                    offset: 8,
                    value: "GA"
                },
                {
                    offset: 12,
                    value: "USA"
                }
            ],
            types: [
                "locality",
                "political",
                "geocode"
            ]
        }
    ]
};

export const recentLocations = {
    "data": [
        {
            "id": "49",
            "type": "location",
            "attributes": {
                "latitude": 38.7624489,
                "longitude": -78.2966226,
                "state": "Virginia",
                "city": "Rileyville",
                "country": "United States",
                "place_id": "ChIJLewFrmS1tYkRPUlC0A4humY"
            }
        },
        {
            "id": "45",
            "type": "location",
            "attributes": {
                "latitude": 39.7589478,
                "longitude": -84.1916069,
                "state": "Ohio",
                "city": "Dayton",
                "country": "United States",
                "place_id": "ChIJAxTdrtWAQIgR5EwO8pLjQKY"
            }
        },
        {
            "id": "44",
            "type": "location",
            "attributes": {
                "latitude": 38.9523404,
                "longitude": -77.4586344,
                "state": "Virginia",
                "city": "Dulles",
                "country": "United States",
                "place_id": "ChIJV7BOF0BHtokRHWCAECDLAY4"
            }
        },
        {
            "id": "25",
            "type": "location",
            "attributes": {
                "latitude": 29.2108147,
                "longitude": -81.0228331,
                "state": "Florida",
                "city": "Daytona Beach",
                "country": "United States",
                "place_id": "ChIJg1YCJZTb5ogR6yrLHbc7ajY"
            }
        },
        {
            "id": "23",
            "type": "location",
            "attributes": {
                "latitude": 17.010564,
                "longitude": 120.919515,
                "state": "Cordillera Administrative Region",
                "city": "Sabangan",
                "country": "Philippines",
                "place_id": "ChIJS1qkkufUjzMR-6z70Y-UsMY"
            }
        },
        {
            "id": "22",
            "type": "location",
            "attributes": {
                "latitude": 40.0470186,
                "longitude": -79.654484,
                "state": "Pennsylvania",
                "city": "Dawson",
                "country": "United States",
                "place_id": "ChIJM5rdVngjNYgRGkxFlnfmtRw"
            }
        },
        {
            "id": "16",
            "type": "location",
            "attributes": {
                "latitude": 39.7444267,
                "longitude": -79.1104583,
                "state": "Pennsylvania",
                "city": "Springs",
                "country": "United States",
                "place_id": "ChIJTcALvDK5yokR9LFOCBwDbTY"
            }
        },
        {
            "id": "1",
            "type": "location",
            "attributes": {
                "latitude": 29.3080255,
                "longitude": 78.5033076,
                "state": "Uttar Pradesh",
                "city": "Dhampur",
                "country": "India",
                "place_id": "ChIJnTSDyCTICzkRUbXfdf4ogg8"
            }
        }
    ]
}
